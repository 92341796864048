/* globals jQuery, twttr */

import svgxhr from 'webpack-svgstore-plugin/src/helpers/svgxhr';
import Swiper from 'swiper/dist/js/swiper.js';
import MicroModal from 'micromodal';
import 'lazysizes';
import Cookies from 'js-cookie';
// import ScrollingSpy from './lib/scrolling-spy';
import WOW from 'wow.js';
import './lib/uisearch';

import Home from './modules/Home';
import Forms from './modules/Forms';
import Nav from './modules/Nav';
// import Topics from './modules/Topics';
// import Tutorial from './modules/Tutorial';
import API from './api';
// import ArticleModal from './modules/ArticleModal';

window.api = API;
window.MicroModal = MicroModal;

window.ovo = {
	init: function() {
		if(typeof NodeList.prototype.forEach !== 'function') {
			NodeList.prototype.forEach = Array.prototype.forEach;
		}

		if(document.getElementById( 'header__search' ) !== null){
			new UISearch( document.getElementById( 'header__search' ) );
		}

		// window.MicroModal.init({
		// 	onShow: modal => {
		// 		if(modal.id === 'modal--login-email') {
		// 			window.MicroModal.show('modal--login');
		// 			window.MicroModal.close('modal--login');
		// 		}
		// 	},
		// 	disableScroll: false,
		// 	awaitCloseAnimation: false,
		// 	debugMode: true,
		// });
		//
		// var welcomeModal = document.getElementById('register');
		// if( welcomeModal ){
		// 	if (document.cookie.split(';').filter((item) => item.trim().startsWith('modal--welcome=')).length == 0) {
		// 		MicroModal.show('modal--welcome', {
		// 			onClose: modal => {
		// 				var expiry = new Date();
		//   				expiry.setTime(expiry.getTime()+(0.5*365*24*60*60*1000)); // 6 months
		//   				document.cookie = "modal--welcome=1; expires=" + expiry.toGMTString();
		// 			}
		// 		});
		// 	}
		// }

		// ScrollingSpy.init();
		var wow = new WOW({
			boxClass: 'scrolling-spy',
			animateClass: 'animated',
			offset: -1,
			mobile: true,
			live: false,
			callback: box => {
				box.classList.add('hit');
			}
		});
		wow.init();
		Nav.init();
		Home.init();
		Forms.init();
		// Topics.init();
		// Tutorial.init();
		// ArticleModal.init();

		window.twitterFeed = new Swiper('.twitter-feed', {
			spaceBetween: 10,
			speed: 400,
			slidesPerView: 4,
			slidesPerGroup: 4,
			loop: false,
			autoplay: {
				delay: 10000,
			},
			allowTouchMove: true,
			// loopedSlides: 5, //looped slides should be the same
			breakpoints : {
				1200: {
					slidesPerView: 3,
					spaceBetween: 10,
					slidesPerGroup: 3
				},
				800: {
					slidesPerView: 2,
					spaceBetween: 10,
					slidesPerGroup: 2
				},
				500: {
					slidesPerView: 1,
					spaceBetween: 10,
					slidesPerGroup: 1
				},
			},
		});

		if(typeof twttr !== 'undefined'){
			twttr.events.bind(
				'rendered',
				function (event) {
					window.twitterFeed.update();
				}
			);
		}

		if(document.querySelector('.post-image-slider') !== null) {
			document.querySelectorAll('.post-image-slider').forEach(el => {
				el.swiper = new Swiper(el, {
					loop: true,
					autoplay: {
						delay: 9000,
					},
					pagination: {
						el: el.querySelector('.swiper-pagination'),
						type: 'bullets',
						clickable: true
					}
				});
			})
		}

		if(document.querySelector('.simple-slider') !== null) {
			document.querySelectorAll('.simple-slider').forEach(el => {
				el.swiper = new Swiper(el, {
					loop: true,
					autoplay: {
						delay: 3000,
					},
				});
			});
		}

		if(document.querySelector('#subscribe-modal') !== null){
			document.querySelectorAll('#subscribe-modal').forEach(el => {
				el.addEventListener('click', ev => {
					ev.preventDefault();

					window.MicroModal.show('modal--subscribe');
				})
			});
		}

		['contact', 'advertise', 'write'].forEach(type => {
			if(document.querySelector('a[href="#' + type + '-modal"]') !== null){
				document.querySelectorAll('a[href="#' + type + '-modal"]').forEach(el => {
					el.addEventListener('click', ev => {
						ev.preventDefault();

						window.MicroModal.show('modal--' + type);
					})
				});
			}
		});

		jQuery( document ).on( 'cf.form.submit', function (event, data ) {
			//data.$form is a jQuery object for the form that just submitted.
			const form = data.$form[0],
				  modal = form.closest('.modal');

			if(modal !== null){
				setTimeout(function(){
					MicroModal.close(modal.getAttribute('id'));
				}, 1000);
			}
		});


		this.fixViewportHeight();
		window.addEventListener('resize', this.fixViewportHeight, { passive: true });

	},
	fixViewportHeight: function () {
		console.log(window.innerHeight);
		let vh = window.innerHeight;
		// Then we set the value in the --vh custom property to the root of the document
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}
};

window.ovo.init();

// SVG Sprite Loader
// eslint-disable-next-line no-unused-vars
const __sprite__ = {
	path: '../../resources/images/icons/**/*.svg', //Source SVGs path relative to the compiled js (dist/js)
	name: 'images/icons.svg', //Compiled file path relative to dist/
};

svgxhr({
	filename: './wp-content/themes/class/dist/images/icons.svg', //URL to fetch
});
