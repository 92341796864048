import Tutorial from './Tutorial';

export default {
	init: function() {
		//Allow user to interrupt auto-scrolling
		$(document).bind('scroll mousedown wheel keyup', function(e) {
			if(e.which || e.type === 'mousedown' || e.type === 'mousewheel') {
				$(document.scrollingElement).stop();
			}
		});

		this.initHeader();
		this.initAccountNav();
		this.initDropdowns();
		this.initTabs();
	},

	initHeader() {
		let header = document.getElementById('header');

		if(header) {
			header.addEventListener('click', e => {
				switch(e.target.id) {
					case 'header__open' :
						e.preventDefault();
						document.body.style.overflowY = 'hidden';
						document.documentElement.classList.add('menu-open');
						header.classList.add('header--open');
						break;
					case 'header__close' :
						e.preventDefault();
						document.body.style.overflowY = '';
						document.documentElement.classList.remove('menu-open');
						header.classList.remove('header--open');
						break;
					case 'header__tutorial' :
						e.preventDefault();
						Tutorial.showTutorial();
				}
			})
		}
	},

	initAccountNav() {
		let accountNav = document.getElementById('account-nav-mobile');

		if(accountNav) {
			accountNav.addEventListener('change', () => {
				window.location.href = accountNav.value;
			});
		}
	},

	initDropdowns() {
		let dropdowns = document.querySelectorAll('.has-dropdown');

		if(!dropdowns) {
			return;
		}

		dropdowns.forEach(el => {
			el.addEventListener('click', e => {
				const dropdown = el.querySelector('.dropdown');

				if(!dropdown) {
					return;
				}


				if(e.target.classList.contains('dropdown-trigger')) {
					e.preventDefault();

					if(!dropdown.classList.contains('open')){
						document.querySelectorAll('.dropdown').forEach(el => {
							el.classList.remove('open');
						});
					}

					dropdown.classList.toggle('open');
				} else if(e.target.classList.contains('dropdown__backdrop')) {
					e.preventDefault();
					dropdown.classList.remove('open');
				}
			});
		});
	},

	initTabs() {
		let tabNavs = document.querySelectorAll('.nav--tabs');

		if(!tabNavs) {
			return;
		}

		tabNavs.forEach(nav => {
			nav.addEventListener('click', e => {
				let target = e.target.hash,
					targetElement = target ? document.querySelector(target) : null;

				if(!targetElement) {
					return;
				}

				e.preventDefault();

				let oldTab = targetElement.parentNode.querySelector('.tab--active'),
					oldTabLink = nav.querySelector('.active');

				if(oldTab) {
					oldTab.classList.remove('tab--active');
				}

				if(oldTabLink) {
					oldTabLink.classList.remove('active');
				}

				e.target.parentNode.classList.add('active');
				targetElement.classList.add('tab--active');
			});
		});
	},
};
