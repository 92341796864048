import Pristine from 'pristinejs';

export default class AJAXForm {

	constructor(form, options) {
		this.options = Object.assign({
			resetOnSuccess: true,
			destroyOnSuccess: false,
		}, options);

		if(!(form instanceof HTMLFormElement)) {
			throw new TypeError("Invalid form element");
		}

		this.form = form;
		this.errors = [];
		this.success = [];

		this._submit = [];
		this._fail = [];
		this._done = [];
		this._always = [];

		this.form.ajaxForm = this;

		this._init();
	}

	_init() {
		let clicked = null;

		this.form.pristine = new Pristine(this.form, {
			classTo: 'form__group',
			errorClass: 'invalid',
			successClass: 'valid',
			errorTextParent: 'form__group',
			errorTextTag: 'span',
			errorTextClass: 'form__validation',
		}, false);

		this.form.addEventListener('click', e => {
			if(e.target.type === 'submit') {
				clicked = e.target;
			}
		});

		this.form.addEventListener('submit', e => {
			e.preventDefault();

			if(clicked && clicked.name) {
				let data = {};

				data[clicked.name] = clicked.value;

				this.submit(data);
			} else {
				this.submit();
			}

			clicked = null;
		});
	}

	_updateMessages(data) {
		data = data || {};

		this.errors = [];
		this.success = [];

		if(!data.success) {
			this.errors = [data.message];

			if(data.data && data.data.params) {
				for(let i = 0; i < this.form.elements.length; i++) {
					let element = this.form.elements[i];

					if(data.data.params[element.name] && element.name !== 'g-recaptcha-response') {
						element.pristine.errors = element.pristine.errors || []; //Fix pristine not initialising its own error array
						this.form.pristine.addError(element, data.data.params[element.name]);
					} else if(data.data.params[element.name] && element.name === 'g-recaptcha-response'){
						let recaptchaFormGroup = this.form.querySelector('.g-recaptcha').parentElement;
						let errorMessageElement = recaptchaFormGroup.querySelector('.pristine-error.form__validation');

						if (!errorMessageElement) {
							// Create span element
							errorMessageElement = document.createElement('span')
							errorMessageElement.className = "pristine-error form__validation";
							recaptchaFormGroup.appendChild(errorMessageElement);
						}
						// update span element inner html
						errorMessageElement.innerHTML = `${data.data.params[element.name]}`
					}
				}
			}
		} else {
			this.success = [data.message];
		}
	}

	_sendRequest(data) {
		let type = this.form.method,
			url = this.form.action;

		return fetch(url, {
			method: type || 'GET',
			redirect: 'error',
			mode: 'cors',

			referer: 'no-referrer',
			headers: {
				'Accept': 'application/json',
				'X-Requested-With' : 'XMLHttpRequest',
				'X-WP-NONCE': window.wpRestApi.nonce,
			},
			body: type !== 'GET' ? data : null,
		}).then((response) => {
			response.json().catch(error => {
				for(let j = 0; j < this._fail.length; j++) {
					this._fail[j].call(this, null, error);
				}
			}).then(data => {
				this._updateMessages(data);

				if(response.ok) {
					for(let i = 0; i < this._done.length; i++) {
						this._done[i].call(this, data);
					}

					if(this.options.resetOnSuccess) {
						this.form.reset();
					}

					if(this.options.destroyOnSuccess) {
						this.destroy();
					}
				} else {
					for(let j = 0; j < this._fail.length; j++) {
						this._fail[j].call(this, data, data.message);
					}
				}
			});
		}, reason => {
			for(let i = 0; i < this._fail.length; i++) {
				this._fail[i].call(this, null, reason);
			}
		}).finally(() => {
			for(let i = 0; i < this.form.elements.length; i++) {
				const element = this.form.elements.item(i);

				if(element.type !== 'submit') {
					continue;
				}

				element.disabled = false;
				element.textContent = element.oldLabel;
			}

			for(let i = 0; i < this._always.length; i++) {
				this._always[i].call(this);
			}
		});
	}
}

//noinspection JSUnusedGlobalSymbols
AJAXForm.prototype.getErrors = function() {
	return this.errors;
};

//noinspection JSUnusedGlobalSymbols
AJAXForm.prototype.getSuccess = function() {
	return this.success;
};

AJAXForm.prototype.getErrorString = function() {
	let errors = [];

	for(let field in this.errors) {
		if(this.errors.hasOwnProperty(field)) {
			let error = this.errors[field];

			errors.push((typeof error.count !== 'undefined') ? error.join('\n') : error);
		}
	}

	return errors.join('\n');
};

AJAXForm.prototype.submit = function(extraData) {
	let data = new FormData();

	if(this.options.data) {
		data = this.options.data.call(this, this.form);
	} else {
		data = new FormData(this.form);
	}

	this.form.querySelectorAll('.alert').forEach(element => {
		element.remove();
	});

	if(extraData) {
		for(let key in extraData) {
			if(extraData.hasOwnProperty(key)) {
				data.append(key, extraData[key]);
			}
		}
	}

	this.form.pristine.reset();
	let valid = this.form.pristine.validate();

	if(!valid) {
		return;
	}

	for(let i = 0; i < this.form.elements.length; i++) {
		const element = this.form.elements.item(i);

		if(element.type !== 'submit' || element.disabled) {
			continue;
		}

		element.disabled = true;
		element.oldLabel = element.textContent;
		element.textContent = element.dataset.submit || 'Submitting...';
	}

	for(let j = 0; j < this._submit.length; j++) {
		this._submit[j].call(this, data);
	}

	this._sendRequest(data);
};

AJAXForm.prototype.done = function(callback) {
	this._done.push(callback);
	return this;
};

AJAXForm.prototype.fail = function(callback) {
	this._fail.push(callback);
	return this;
};

AJAXForm.prototype.always = function(callback) {
	this._always.push(callback);
	return this;
};

// noinspection JSUnusedGlobalSymbols
AJAXForm.prototype.preSubmit = function(callback) {
	this._submit.push(callback);
	return this;
};

AJAXForm.prototype.destroy = function() {
	delete this.form.ajaxForm;
	delete this.form;
};
