import Swiper from 'swiper/dist/js/swiper.js';
import Cookies from 'js-cookie';
import MicroModal from 'micromodal';

let tutorialModal, tutorialSwiper, nextButton, closeButton;

export default {
	init: function() {
		console.log('Tutorial:init()');

		tutorialModal = document.getElementById('modal--tutorial');

		if(tutorialModal) {
			tutorialSwiper = tutorialModal.querySelector('#tutorial-swiper');
			nextButton = tutorialModal.querySelector('.btn--next');
			closeButton = tutorialModal.querySelector('.btn--close');

			nextButton.addEventListener('click', e => {
				e.preventDefault();
				tutorialSwiper.swiper.slideNext();
			});

			closeButton.addEventListener('click', e => {
				MicroModal.close('modal--tutorial');
				MicroModal.show('modal--login');
				e.preventDefault();
			});
		}

		if(document.querySelector('#modal--personlise-topics') !== null && typeof Cookies.get(
			'personalise_reminder') === 'undefined') {
			MicroModal.show('modal--personlise-topics');
			Cookies.set('personalise_reminder', true, {
				expires: 0.125
			});
		}
	},

	showTutorial() {
		if(!tutorialModal) {
			return;
		}

		window.MicroModal.show('modal--tutorial');

		if(tutorialSwiper.swiper) {
			return;
		}

		tutorialSwiper.swiper = new Swiper(tutorialSwiper, {
			loop: false,
			freeMode: false,
			autoplay: {
				delay: 5000,
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			on: {
				slideChange: () => {
					if(tutorialSwiper.swiper.activeIndex === tutorialSwiper.swiper.slides.length - 1) {
						nextButton.style.display = 'none';
						closeButton.style.display = 'block';
					} else {
						nextButton.style.display = '';
						closeButton.style.display = '';
					}
				}
			},
		});
	}
};
