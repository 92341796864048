let socialModal, registerform, loginForm, forgotPassword;

export default {
	init() {
		socialModal = document.getElementById('modal--login');
		registerform = document.getElementById('register');
		loginForm = document.getElementById('login');
		forgotPassword = document.getElementById('forgot');

		if(!registerform || !loginForm) {
			return;
		}

		registerform.ajaxForm.done(() => {
			this.fireEvent('button_click', 'sign_up', 'Email', 'Email');
			window.location.reload();
		});
		loginForm.ajaxForm.done(() => window.location.reload());
		forgotPassword.ajaxForm.done(() => {
			forgotPassword.style.display = 'none';

			forgotPassword.nextElementSibling.style.display = 'block';
		});

		if(!socialModal) {
			return;
		}

		let facebookButton = socialModal.querySelector('.btn--facebook'),
			googleButton = socialModal.querySelector('.btn--google'),
			twitterButton = socialModal.querySelector('.btn--twitter');

		facebookButton.addEventListener('click', e => {
			this.fireEvent('button_click', 'sign_up', 'Facebook', 'Facebook');
		});

		googleButton.addEventListener('click', e => {
			this.fireEvent('button_click', 'sign_up', 'Google', 'Google');
		});

		twitterButton.addEventListener('click', e => {
			this.fireEvent('button_click', 'sign_up', 'Twitter', 'Twitter');
		});
	},

	fireEvent(category, action, label, value) {
		if(window.gtag) {
			window.gtag('event', action, {
				'transport_type': 'beacon',
				'event_category': category,
				'event_label': label,
				'value': value
			});
		}
	}
};
