export default {
	baseUrl: '/wp-json/ovo/v1/',

	login({username, password}) {
		return this._makeRequest('account/login', 'POST', {
			username: username || '',
			password: password || '',
		});
	},

	register({firstName, surname,  email, password}) {
		return this._makeRequest('account', 'POST', {
			firstName: firstName || '',
			surname: surname || '',
			email: email || '',
			password: password || '',
		});
	},

	deleteAccount({password}) {
		return this._makeRequest('account', 'DELETE', {
			password: password || '',
		});
	},

	updateAccount({firstName, lastName, email, nickname}) {
		return this._makeRequest('account', 'PUT', {
			firstName: firstName || '',
			lastName: lastName || '',
			email: email || '',
			nickname: nickname || '',
		});
	},

	followTopic(topic) {
		return this._makeRequest('followed', 'POST', {
			topic: topic,
		});
	},

	unfollowTopic(topic) {
		return this._makeRequest(`followed/${topic}`, 'DELETE');
	},

	setFollowedTopics(topics) {
		return this._makeRequest(`followed`, 'PUT', {
			topics: topics || [],
		});
	},

	favouritePost(post) {
		return this._makeRequest('favourites', 'POST', {
			post: post,
		});
	},

	unfavouritePost(post) {
		return this._makeRequest(`favourites/${post}`, 'DELETE');
	},

	createList({title, post}) {
		return this._makeRequest('lists', 'POST', {
			title: title || '',
			post: post || null,
		});
	},

	addToList(list, post) {
		return this._makeRequest(`lists/${list}`, 'POST', {
			post: post,
		});
	},

	removeFromList(list, post) {
		return this._makeRequest(`lists/${list}/${post}`, 'DELETE');
	},

	updateList(list, {status, title}) {
		return this._makeRequest(`lists/${list}`, 'PUT', {
			title: title || '',
			status: status || 'public',
		});
	},

	deleteList(list) {
		return this._makeRequest(`lists/${list}`, 'DELETE');
	},

	_makeRequest(url, type, data) {
		data = data || {};
		let formData;

		if(!(data instanceof FormData)) {
			formData = new FormData();
			Object.keys(data).forEach(key => formData.append(key, data[key]));
		} else {
			formData = data;
		}

		return fetch(`${this.baseUrl}${url}`, {
			method: type || 'GET',
			redirect: 'error',
			mode: 'cors',
			referer: 'no-referrer',
			headers: {
				'Accept': 'application/json',
				'X-Requested-With' : 'XMLHttpRequest',
				'X-WP-NONCE': window.wpRestApi.nonce,
			},
			body: type !== 'GET' ? formData : null,
		}).then(response => {
			return response.json().then(json => {
				if(response.ok) {
					return json;
				} else {
					let errors = {};

					if(json.exception || json.exception) {
						errors.server = [json.message || json.exception];
					}

					response.errors = json.errors || errors;

					console.error(response);

					return Promise.reject(response);
				}
			});
		})
	}
}
