import AJAXForm from '../lib/AJAXForm';
import AddToListModal from './AddToListModal';
import ShareModal from './ShareModal';
import DeleteListModal from './DeleteListModal';
import LoginModal from './LoginModal';
import PhotoForm from './PhotoForm';
import API from '../api';

export default {
	init() {
		document.querySelectorAll('.form--ajax').forEach(form => this.initForm(form));

		document.body.addEventListener('click', e => {
			if(e.target.classList.contains('btn--follow')) {
				this.setTopicFollowed(true, e.target);
			}

			if(e.target.classList.contains('btn--unfollow')) {
				this.setTopicFollowed(false, e.target);
			}

			if(e.target.classList.contains('post__add')) {
				AddToListModal.setPost(e.target.dataset.post);
			}

			if(e.target.classList.contains('post__favourite')) {
				this.setPostFavourited(true, e.target);
			}

			if(e.target.classList.contains('post__unfavourite')) {
				this.setPostFavourited(false, e.target);
			}

			if(e.target.classList.contains('post__share')) {
				ShareModal.setInfo(e.target.dataset.url, e.target.dataset.title);
			}

			if(e.target.classList.contains('post__remove')) {
				this.removePost(e.target);
			}
		});

		PhotoForm.init();
		LoginModal.init();
		AddToListModal.init();
		ShareModal.init();
		DeleteListModal.init();
	},

	initForm(form) {
		let modal = form.closest('.modal');
		let ajaxForm = new AJAXForm(form, {
			resetOnSuccess: !!modal,
		});

		ajaxForm.done(() => {
			if(modal && typeof modal.getAttribute('data-dont-close') === 'undefined') {
				window.MicroModal.show(modal.id); //.close breaks without .show first
				window.MicroModal.close(modal.id);
			} else if(form.getAttribute('data-redirect') !== null) {
				window.location = form.getAttribute('data-redirect');
			} else {
				let alert = document.createElement('div');
				alert.className = 'alert alert--success';
				alert.innerText = ajaxForm.getSuccess().join('\n');

				form.insertAdjacentElement('afterbegin', alert);
			}
		});


		ajaxForm.fail((response, error) => {
			let alert = document.createElement('div');
			alert.className = 'alert';
			alert.innerText = error || ajaxForm.getErrors().join('\n');

			form.insertAdjacentElement('afterbegin', alert);
		})
	},

	setTopicFollowed(follow, button) {
		let request;

		button.oldLabel = button.innerText;
		button.innerText = follow ? 'Following...' : 'Unfollowing...';
		button.disabled = true;

		if(follow) {
			request = API.followTopic(button.dataset.topic);
		} else {
			request = API.unfollowTopic(button.dataset.topic);
		}

		request.then(() => {
			button.textContent = button.oldLabel;
			button.style.display = 'none';

			if(follow) {
				button.nextElementSibling.style.display = 'block';
			} else {
				button.previousElementSibling.style.display = 'block';
			}
		}).catch(() => {
			button.innerText = 'Error';
		}).finally(() => {
			button.disabled = false;
		});
	},

	setPostFavourited(favourite, button) {
		let request;

		button.disabled = true;

		if(favourite) {
			request = API.favouritePost(button.dataset.post);
		} else {
			request = API.unfavouritePost(button.dataset.post);
		}

		request.then(() => {
			button.style.display = 'none';

			if(favourite) {
				button.nextElementSibling.style.display = 'block';
			} else {
				button.previousElementSibling.style.display = 'block';
			}
		}).finally(() => {
			button.disabled = false;
		});
	},

	removePost(button) {
		let postID = button.dataset.post,
			list = button.dataset.list,
			request;

		button.disabled = true;
		button.innerText = 'Removing...';

		if(list) {
			request = API.removeFromList(list, postID);
		} else {
			request = API.unfavouritePost(postID);
		}

		request.then(() => {
			let post = button.closest('.post');

			post.classList.add('post--removed');
			post.addEventListener('transitionend', e => post.remove());
		}).catch(() => {
			button.innerText = 'Error';
			button.disabled = false;
		});
	}
}
