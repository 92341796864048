import Swiper from 'swiper/dist/js/swiper.js';

export default {
	init() {

		let featuredSwiper = new Swiper(document.querySelector('#featured-posts .swiper-container'), {
			speed: 400,
			spaceBetween: 0,
			direction: 'vertical',
			effect: 'fade',
			allowTouchMove: false,
			fadeEffect: {
				crossFade: true
			},
			pagination: {
				el: '.swiper-pagination',
				type: 'bullets',
				clickable: true,
			},
			loop: true,
			autoplay: {
				delay: 12000,
			},
			on: {
				slideChange: function(swiper){
					const activeSlide = this.slides[this.activeIndex];
					if(activeSlide.classList.contains('swiper-slide--right')){
						this.el.classList.add('swiper-container--right');
					} else{
						this.el.classList.remove('swiper-container--right');

					}
				}
			}
		});
	},
}
