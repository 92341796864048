let existingForm, newForm;

export default {
	init() {
		existingForm = document.getElementById('existing-list');
		newForm = document.getElementById('new-list');

		if(!existingForm || !newForm) {
			console.error("Required AddToListModal elements missing");
			return;
		}

		existingForm.elements.list.addEventListener('change', e  => {
			existingForm.action = `${window.wpRestApi.baseUrl}/lists/${e.target.value}`;
		});

		this.setLists(window.lists || {});
	},

	setPost(id) {
		existingForm.elements.post.value = newForm.elements.post.value = id;
	},

	setLists(lists) {
		this.clearLists();
		this.addList('', 'Select list');

		for(let id in lists) {
			if(!lists.hasOwnProperty(id)) {
				continue;
			}

			this.addList(id, lists[id]);
		}
	},

	clearLists() {
		existingForm.elements.list.innerHTML = '';
	},

	addList(id, title) {
		let option = document.createElement('option');
		option.innerText = title;
		option.value = id;

		existingForm.elements.list.add(option);
	},
};
