let photoForm, input, deleteButton, photo;
const defaultAvatar = '/wp-content/themes/class/dist/images/default-avatar.jpg';

export default {
	init() {
		photoForm = document.getElementById('avatar');
		deleteButton = document.getElementById('avatar__delete');
		photo = document.querySelector('#avatar__image image');
		input = document.querySelector('#avatar__input');

		if(!photoForm || !photo) {
			return;
		}

		if(deleteButton){
			deleteButton.addEventListener('click', () => {
				photo.setAttribute('xlink:href', defaultAvatar);
				photoForm.elements.avatar.value = '';
				input.removeAttr('data-filename');
			});
		}

		input.addEventListener('change', e => {
			if(e.target.files && e.target.files[0]) {
				input.dataset.filename = e.target.files[0].name;
			} else {
				input.removeAttr('data-filename');
			}
		});

		photoForm.ajaxForm.done(response => {
			photo.setAttribute('xlink:href', response.url || defaultAvatar);
		});
	},
};
