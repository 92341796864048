let modal, form;

export default {
	init() {
		modal = document.getElementById('modal--delete-list');
		form = modal ? modal.querySelector('.form--ajax') : null;

		if(!modal || !form) {
			return;
		}

		form.ajaxForm.done(() => window.location.href = '/account/lists');
	},
};
