let modal, copyButton, url, title;

export default {
	init() {
		modal = document.getElementById('modal--share');
		copyButton = document.getElementById('copy-url');

		if(!modal || !copyButton) {
			console.error("Required ShareModal elements missing");
			return;
		}

		copyButton.addEventListener('click', e  => {
			let textarea = document.createElement('textarea');
			// textarea.hidden = true;
			textarea.value = url;

			copyButton.insertAdjacentElement('afterend', textarea);
			textarea.className = 'sr-only';
			textarea.focus();
			textarea.select();

			document.execCommand('copy');
			textarea.remove();
		});
	},

	setInfo(newUrl, newTitle) {
		url = newUrl;
		title = newTitle;

		if(typeof modal === 'undefined')
			return;

		let links =	modal.querySelectorAll('a');

		links.forEach(link => {
			let shareUrl = link.dataset.href.replace('%url%', encodeURIComponent(url));
			shareUrl = shareUrl.replace('%title%', encodeURIComponent(title));

			link.href = shareUrl;
		});
	},
};
